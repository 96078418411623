import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ContactPage from "./pages/ContactPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ToolDetail from "./pages/ToolDetail";
import ToolListPage from "./pages/ToolListPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ToolListPage />,
  },
  {
    path: "/tools/:toolId",
    element: <ToolDetail />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
