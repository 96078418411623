import { Link } from "react-router-dom";
import styled from "styled-components";

export default function DefaultLayout({ children }) {
  return (
    <Layout>
      <Header>
        <HeaderTitle to="/">Agent Tool Registry</HeaderTitle>
      </Header>
      <Main>
        <MainContainer>{children}</MainContainer>
      </Main>
      <Footer>
        <FooterLink to="/privacy-policy">プライバシーポリシー</FooterLink>
        <p>©︎ 2024 Generative Agents Inc. All Rights Reserved.</p>
      </Footer>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #f3f3f3;
  height: 50px;
  padding: 0 36px;
`;

const HeaderTitle = styled(Link)`
  font-size: 1.25rem;
  font-weight: bold;
  color: #333333;
  text-decoration: none;
`;

const Main = styled.main`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  display: flex;
  color: #333333;

  h1,
  h2,
  h3 {
    color: #111111;
  }
`;

const MainContainer = styled.div`
  width: min(800px, 90%);
  padding: 24px 36px;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333333;
  color: #aaaaaa;
  font-size: 10px;
  height: 60px;
  gap: 4px;
`;

const FooterLink = styled(Link)`
  color: #aaaaaa;
`;
