import { Link } from "react-router-dom";
import styled from "styled-components";

import DefaultLayout from "../components/DefaultLayout";
import tools from "../data/tool-definition";

export default function ToolListPage() {
  return (
    <DefaultLayout>
      <ToolList>
        {tools.map((tool) => (
          <Tool to={`/tools/${tool.id}`} key={tool.name}>
            <ToolIconArea>
              <ToolIcon src={tool.iconSrc} alt="" />
            </ToolIconArea>
            <ToolTitleArea>
              <p>{tool.name}</p>
            </ToolTitleArea>
          </Tool>
        ))}
      </ToolList>
    </DefaultLayout>
  );
}

const ToolList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px 16px;
`;

const Tool = styled(Link)`
  display: grid;
  grid-template-areas: "icon title";
  grid-template-columns: max-content 1fr;
  flex-grow: 1;
  flex-shrink: 0;
  gap: 8px 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 16px 16px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #dff2fc;
    color: red;
  }
`;

const ToolIconArea = styled.div`
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ToolIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  pointer-events: none;
`;

const ToolTitleArea = styled.h2`
  grid-area: title;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  color: #333333;
  pointer-events: none;
`;
