import { Link, useParams } from "react-router-dom";

import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import DefaultLayout from "../components/DefaultLayout";
import tools from "../data/tool-definition";

export default function ToolDetail() {
  const { toolId } = useParams();
  const tool = useMemo(() => tools.find((t) => t.id === toolId), [toolId]);
  return (
    <DefaultLayout>
      <PageRoot>
        <ToolInfoArea>
          <ToolIconArea>
            <ToolIcon src={tool.iconSrc} alt="" />
          </ToolIconArea>
          <ToolName>{tool.name}</ToolName>
          <ToolDescription>{tool.description}</ToolDescription>
        </ToolInfoArea>
        <CopyFormList>
          <CopyForm
            label="スキーマURL"
            text={`${window.location.origin}${tool.apiSchemaPath}`}
          />
        </CopyFormList>
        <Accordion>
          <summary>自作のGPTsへ組み込む方法</summary>
          <AccordionContent>
            <h2>Step1： 新しいアクションを追加する</h2>
            <p>{`GPTs作成画面の「アクション > 新しいアクションを作成する」ボタンから新しいGPTの作成画面へ遷移します。`}</p>
            <h2>Step2： Basic認証を設定する</h2>
            <p>
              「認証」をクリックし、認証タイプを上から「APIキー」「Basic認証」に設定の上、「APIキー」の入力欄に共有されたAPIキーを貼り付けてください。
            </p>
            <img src="/images/gpts-basic-auth.png" alt="" />
            <h2>Step3： スキーマをインポートする</h2>
            <p>
              「スキーマ」欄の「URLからインポートする」を選択し、本ページからコピーしたスキーマURLを貼り付けた上で「インポートする」をクリックしてください。
            </p>
            <img src="/images/gpts-openai-schema.png" alt="" />
            <h2>Step4： プライバシーポリシーを入力する</h2>
            <p>
              「プライバシーポリシー」欄に当サイトの
              <Link to="/privacy-policy">プライバシーポリシー</Link>
              のURLを入力してください。
            </p>
          </AccordionContent>
        </Accordion>
      </PageRoot>
    </DefaultLayout>
  );
}

const PageRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const ToolInfoArea = styled.div`
  display: grid;
  grid-template-areas: "icon name" "icon description";
  grid-template-columns: max-content 1fr;
  gap: 16px 24px;
`;

const ToolIconArea = styled.div`
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ToolIcon = styled.img`
  width: 64px;
  height: 64px;
  object-fit: contain;
`;

const ToolName = styled.h1`
  grid-area: name;
  font-size: 1.5rem;
`;

const ToolDescription = styled.p`
  grid-area: description;
`;

const CopyFormList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

function CopyForm({ isPassword, label, text }) {
  const onCopy = useCallback(() => {
    const copyToClipboard = async () => {
      navigator.clipboard.writeText(text);
    };
    copyToClipboard();
  }, [text]);
  return (
    <CopyFormStyle>
      <CopyFormLabel>{label}</CopyFormLabel>
      <CopyFormInputStyle
        type={isPassword ? "password" : "text"}
        value={text}
        disabled
      />
      <CopyButton onCopy={onCopy} />
    </CopyFormStyle>
  );
}

const CopyFormStyle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

const CopyFormLabel = styled.label`
  width: 120px;
`;

const CopyFormInputStyle = styled.input`
  width: 400px;
  background-color: #eeeeee;
`;

function CopyButton({ onCopy }) {
  const [copied, setCopied] = useState(false);
  const onClick = useCallback(() => {
    if (copied) return;
    setCopied(true);
    onCopy();
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [onCopy, copied]);
  return (
    <CopyButtonStyle copied={copied} onClick={onClick}>
      {copied ? "Copied!" : "Copy"}
    </CopyButtonStyle>
  );
}

const CopyButtonStyle = styled.button`
  background-color: ${(props) => (props.copied ? "#dff2fc" : "#fafafa")};
  border: 1px solid #555555;
  color: ${(props) => (props.copied ? "#00b16b" : "#333333")};
  cursor: pointer;
  width: 80px;

  &:hover {
    background-color: #eaeaea;
  }
`;

const Accordion = styled.details`
  border: 1px solid #333333;
  border-radius: 4px;
  padding: 8px 16px;
`;

const AccordionContent = styled.div`
  padding: 8px 16px;
  font-size: 0.85rem;
  margin-top: 16px;

  h2 {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  h2:not(:first-child) {
    margin-top: 16px;
  }

  p {
    margin-bottom: 8px;
  }
`;
