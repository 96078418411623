const tools = [
  {
    id: "md-to-pdf",
    name: "Markdown→PDF変換",
    description: "MarkdownからPDFファイルを生成するツールです",
    apiSchemaPath: "/api/v1/tools/md-to-pdf/openapi.json",
    iconSrc: "/images/file.png",
  },
  {
    id: "md-to-docx",
    name: "Markdown→Docx（Word）変換",
    description: "MarkdownからWord用のDocxファイルを生成するツールです",
    apiSchemaPath: "/api/v1/tools/md-to-docx/openapi.json",
    iconSrc: "/images/file.png",
  },
  {
    id: "md-to-pptx",
    name: "Markdown→PPTX（PowerPoint）変換",
    description: "MarkdownからPowerPoint用のPPTXファイルを生成するツールです",
    apiSchemaPath: "/api/v1/tools/md-to-pptx/openapi.json",
    iconSrc: "/images/file.png",
  },
  {
    id: "pdf-to-page-images",
    name: "PDF→ページ画像変換",
    description: "PDFファイルをページごとの画像に変換するツールです",
    apiSchemaPath: "/api/v1/tools/pdf-to-page-images/openapi.json",
    iconSrc: "/images/file.png",
  },
];

export default tools;
