import styled from "styled-components";

import { Link } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";

export default function PrivacyPolicyPage() {
  return (
    <DefaultLayout>
      <PrivacyPolicy>
        <h1>プライバシーポリシー</h1>
        <h2>はじめに</h2>
        <p>
          当社は本サービスを運営する上で、個人情報保護の重要性を強く認識し、個人情報を取り扱う際には個人情報保護に関する法令・国が定める指針・その他の規範を遵守し、個人情報の取得、利用、管理を利用目的の範囲内で適切に行います。
        </p>
        <h2>個人情報の定義</h2>
        <p>
          本プライバシーポリシーにおける個人情報とは、本サービスを通じて利用者から取得する氏名、メールアドレス、電話番号その他の個人を特定できる情報を指します。
        </p>
        <h2>個人情報の利用目的について</h2>
        <p>
          当社は、本サービスで取得した個人情報を以下の目的で利用することができるものとします。
        </p>
        <ul>
          <li>本サービスの運営および運営上必要な情報の提供</li>
          <li>本サービスの改善や新規開発に必要なデータの解析や分析</li>
          <li>メールマガジンの送信</li>
          <li>当社及び第三者の商品等の広告または宣伝</li>
          <li>
            利用者の皆様の行動、性別、本サービス内でのアクセス履歴などを用いたターゲティング広告の配信
          </li>
          <li>契約や法律等に基づく権利の行使や義務の履行</li>
          <li>アフターサービス、各種問い合わせ対応</li>
        </ul>
        <h2>個人情報の第三者への開示</h2>
        <p>
          当社は、以下に定める場合を除いて、事前に利用者本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を利用または共有することはありません。
        </p>
        <ul>
          <li>
            利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
          </li>
          <li>情報収集モジュール提供者へ個人情報が提供される場合</li>
          <li>
            契約や法律等に基づく権利の行使や義務の履行 - 利用者の同意がある場合
          </li>
          <li>その他、個人情報保護法その他の法令で認められる場合</li>
        </ul>
        <h2>クッキーの利用について</h2>
        <p>
          当サービスは、コンテンツの充実やサイト改善のため、後述するアクセス解析サービスを利用しており、これらのサービスを利用する上で、Cookie
          を利用して情報を収集することがあります。
        </p>
        <p>
          ただし、Cookie
          により取得する情報には、ユーザ名やメールアドレスなど個人を特定するものは一切含まれません。
          ブラウザでクッキーの受け入れを拒否するように設定することで、Cookie
          による情報収集を拒否することも可能です。
        </p>
        <p>
          その際は当サイトが正しく機能しない場合もありますので、あらかじめご了承ください。
        </p>
        <h2>当サービスが利用しているサービス</h2>
        <h3>Google Analytics</h3>
        <p>
          当サービスでは、アクセス解析ツール「GoogleAnalytics」を利用しています。
          Google Analyticsはトラフィックデータの収集のために Cookie
          を使用しています。
          トラフィックデータは匿名で収集されており、個人を特定するものではありません。
        </p>
        <p>
          Google Analytics の利用規約は「
          <Link to="https://marketingplatform.google.com/about/analytics/terms/jp/">
            Googleアナリティクス利用規約
          </Link>
          」をご覧ください。
        </p>
        <h2>個人情報の開示、訂正、削除について</h2>
        <p>
          本サービスにおける個人情報の開示・訂正・削除又は利用停止等の措置については、ご本人であることを確認させていただいた上、速やかに対応させていただきます。
        </p>
        <p>
          ただし、個人情報保護法その他の法令により当社がこれらの義務を負わない場合、正当な理由なく同内容の請求が何度も繰り返される場合、又は過度な技術的作業を要する場合は、これらの手続を行うことができない場合があります。
        </p>
        <h2>プライバシーポリシーの更新について</h2>
        <p>
          当社は、本プライバシーポリシーの内容を適宜見直し改善に努めます。
          修正された最新のプライバシーポリシーは常に本ページにて開示されます。
        </p>
        <h2>プライバシーポリシーに関するお問い合わせ</h2>
        <p>当社の個人情報の取扱に関しては、以下のページからご連絡ください。</p>
        <Link to="/contact">お問い合わせ</Link>
        <DateOfEnactment>2024年 6月 15日 制定</DateOfEnactment>
      </PrivacyPolicy>
    </DefaultLayout>
  );
}

const PrivacyPolicy = styled.div`
  color: #333333;

  h1 {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 40px;
    color: #111111;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 16px;
    color: #111111;
    text-decoration: underline;
  }

  h3 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #111111;
  }

  p {
    margin-bottom: 16px;
  }

  li {
    margin-left: -20px;
  }
`;

const DateOfEnactment = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
`;
